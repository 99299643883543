import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { ResponsiveLine } from "@nivo/line";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useGetStatisticsQuery } from "../../state/api";
import { Statistics, makeLogout } from "../../state";
import { DateTime } from "luxon";
import { useAppDispatch } from "../../hooks";
import { useGesture } from "react-use-gesture"; // Gesture handling for panning

const ENTRIES_TO_SHOW = 28; // Number of most recent entries to show initially

const Daily = () => {
  const { data, isError, error, refetch }: any = useGetStatisticsQuery("day");
  const theme: any = useTheme();
  const dispatch = useAppDispatch();
  const [startIndex, setStartIndex] = useState(0); // Track the starting index of the visible data range

  // Set startIndex to the last entries when data is fetched
  useEffect(() => {
    if (data && data.statistics) {
      const initialIndex = Math.max(
        data.statistics.length - ENTRIES_TO_SHOW,
        0
      );
      setStartIndex(initialIndex); // Show the last `ENTRIES_TO_SHOW` entries first
    }
  }, [data]);

  // Handle the error for forbidden access
  useEffect(() => {
    if (isError && error && error.originalStatus === 403) {
      dispatch(makeLogout());
    }
  }, [isError, error, dispatch]);

  const handleRefresh = () => {
    refetch();
  };

  // Gesture hook to handle panning
  const bind = useGesture({
    onDrag: ({ movement: [mx], memo = startIndex }) => {
      const pointsToMove = Math.floor(mx / 50); // Adjust sensitivity
      const newIndex = memo - pointsToMove;

      // Ensure we stay within bounds
      if (data) {
        const maxIndex = data.statistics.length - ENTRIES_TO_SHOW;
        setStartIndex(Math.max(0, Math.min(newIndex, maxIndex)));
      }

      return memo;
    },
  });

  // Prepare the data for the chart and show only visible entries based on startIndex
  const currentData = useMemo(() => {
    if (!data || !data.statistics) return [];

    // Ensure we are within bounds and showing at least `ENTRIES_TO_SHOW` entries
    const visibleData = data.statistics.slice(
      startIndex,
      startIndex + ENTRIES_TO_SHOW
    );

    return visibleData.map((statistic: Statistics) => ({
      x: DateTime.fromISO(new Date(statistic.createdAt).toISOString()).toFormat(
        "dd/MM/yyyy"
      ),
      y: statistic.accountsCreated,
      usersLogged: statistic.usersLogged,
    }));
  }, [data, startIndex]);

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        sx={{
          display: "flex",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Header title="DAILY ACCOUNTS INFO" subtitle="Chart of daily entries" />
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          size="medium"
          onClick={handleRefresh}
          color="secondary"
        >
          Refresh
        </Button>
      </Box>

      <Box height="75vh" {...bind()} style={{ touchAction: "none" }}>
        {data ? (
          <ResponsiveLine
            data={[
              {
                id: "Accounts Created",
                color: "hsl(211, 70%, 50%)",
                data: currentData.map((d: any) => ({
                  x: d.x,
                  y: d.y,
                })),
              },
              {
                id: "Users Logged",
                color: "hsl(322, 70%, 50%)",
                data: currentData.map((d: any) => ({
                  x: d.x,
                  y: d.usersLogged,
                })),
              },
            ]}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: theme.palette.secondary[100],
                  },
                },
                legend: {
                  text: {
                    fill: theme.palette.secondary[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: theme.palette.secondary[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: theme.palette.secondary[100],
                  },
                },
              },
              legends: {
                text: {
                  fill: theme.palette.secondary[100],
                  fontSize: 14, // Set the font size for legend text
                },
              },
              tooltip: {
                container: {
                  color: theme.palette.primary.main,
                },
              },
            }}
            colors={{ datum: "color" }}
            margin={{ top: 50, right: 50, bottom: 70, left: 75 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              legend: "Day",
              legendOffset: 60,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Count",
              legendOffset: -65,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "top-left",
                direction: "column",
                justify: false,
                translateX: 50,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            tooltip={({ point }) => {
              return (
                <div
                  style={{
                    background: theme.palette.background.paper,
                    padding: "6px 12px",
                    border: `1px solid ${point.borderColor}`,
                  }}
                >
                  <div>
                    <strong>Day:</strong> {point.data.xFormatted}
                  </div>
                  <div>
                    <strong>Count:</strong> {point.data.y as number}
                  </div>
                </div>
              );
            }}
          />
        ) : (
          <>Loading...</>
        )}
      </Box>
    </Box>
  );
};

export default Daily;
